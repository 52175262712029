<router-outlet></router-outlet>
<mat-toolbar class="position-fixed bottom-0 d-flex flex-column flex-md-row justify-content-between align-items-center">
    <span class="d-none d-md-flex">&nbsp;</span>
    <span class="text-black text-sm mt-2 mt-md-0">{{footer}}</span>
    <div class="text-center text-muted">
        <small>
            <strong>v{{appVersion}}</strong>
        </small>
    </div>
</mat-toolbar>
