import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {Injectable, Injector} from '@angular/core'
import {Router} from '@angular/router'
import {Observable} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {AuthService} from '../../../auth/auth.service'
import {SnackBarService} from '../../../shared/snackbar/SnackBar.service'

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(
        public injector: Injector,
        private auth: AuthService,
        private snackbarService: SnackBarService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(error => this.errorHandler(error)))
    }

    private async errorHandler(error: HttpErrorResponse): Promise<HttpEvent<any>> {
        const router = this.injector.get(Router)
        if (error.status === 401) {
            this.auth.setToken(null)
            await router.navigate(['/auth'], {replaceUrl: true})
        }

        if (error.status === 404) {
            await router.navigate(['/app'], {replaceUrl: true})
            this.snackbarService.openDanger('Not found')
        }

        throw error
    }
}
