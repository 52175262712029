import {Injectable} from '@angular/core'
import {ISearchParameters} from './base.types'
import {firstValueFrom, Observable} from 'rxjs'
import {HttpClient} from '@angular/common/http'

@Injectable({providedIn: 'root'})
export abstract class BaseService<T> {
    protected resource: string

    protected constructor(
        protected http: HttpClient) {
        this.setDefaults()
    }

    abstract setDefaults()

    /**
     * Set the resource
     * @param resource
     */
    setResource(resource: string) {
        this.resource = resource
    }

    async fetchItems(params?: ISearchParameters): Promise<{records: T[], count: number}> {
        return firstValueFrom(this.fetchItems$(params))
    }

    fetchItems$(params?: ISearchParameters, customUrl?: string): Observable<{records: T[], count: number}> {
        let urlParams: any = {
            filter: undefined,
            sort: undefined,
            pagination: {
                page: 0,
                perPage: 50,
            }

        }
        if (params?.pagination) {
            urlParams.page = params.pagination ? params.pagination.page : 0
            urlParams.perPage = params.pagination ? params.pagination.perPage : 50
        }

        if (params?.filter) {
            urlParams = {...urlParams, filter: encodeURIComponent(JSON.stringify(params.filter))}
        }

        if (params?.sort) {
            urlParams.sort = encodeURIComponent(JSON.stringify(params.sort))
        }

        let urlParamsArray: string[] = []

        if (urlParams) {
            Object.keys(urlParams).map(key => {
                if (urlParams[key] === undefined) return
                urlParamsArray?.push(`${key}=${urlParams[key]}`)
            })
        }

        try {
            const url = customUrl ? customUrl : this.resource
            return this.http.get<{
                records: T[],
                count: number
            }>(`${url}${urlParamsArray ? `?${urlParamsArray?.join('&')}` : ''}`)
        } catch (error: any) {
            throw new Error(error.message)
        }
    }

    async fetchItem(id: string): Promise<T> {
        try {
            return await firstValueFrom(this.http.get<T>(`${this.resource}/${id}`))
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async createItem(payload: any): Promise<any> {
        try {
            return firstValueFrom(this.http.post(this.resource, payload))
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async updateItem(id: string, payload: any): Promise<any> {
        try {
            return firstValueFrom(this.http.put(`${this.resource}/${id}`, payload))
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async deleteItem(id: string): Promise<void> {
        try {
            return firstValueFrom(this.http.delete<void>(`${this.resource}/${id}`))
        } catch (error: any) {
            throw new Error(error)
        }
    }
}
