import {Pipe, PipeTransform} from '@angular/core'
import {fileTypeMap} from '../../protected/proposal/proposal.utils'
import {IProposalFile} from '../../protected/proposal/proposal.types'

@Pipe({name: 'fileIcon'})
export class FileIconPipe implements PipeTransform {
    transform(file: IProposalFile): string {
        if (file.name.toLowerCase().includes('quickbooks')) {
            return '/assets/icons/qb.png'
        } else {
            if (fileTypeMap.has(file.mimeType)) {
                return fileTypeMap.get(file.mimeType).icon
            } else {
                return '/assets/icons/csv.svg'
            }
        }
    }
}
