import {Pipe, PipeTransform} from '@angular/core'
import {fileTypeMap} from '../../protected/proposal/proposal.utils'

@Pipe({name: 'fileType'})
export class FileTypePipe implements PipeTransform {
    transform(mimeType: string): string {
        if (fileTypeMap.has(mimeType)) {
            return fileTypeMap.get(mimeType).type
        } else {
            return 'type not found'
        }
    }
}
