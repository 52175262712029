export const fileTypeMap = new Map<string, {type: string, color: string, icon: string}>([
    (['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', {
        type: 'xlsx',
        icon: '/assets/icons/xls.svg',
        color: '#198754',
    }]),
    ['application/pdf', {
        type: 'pdf',
        icon: '/assets/icons/pdf.svg',
        color: '#DC3545',
    },],
    ['text/csv', {
        type: 'csv',
        icon: '/assets/icons/csv.svg',
        color: '#1A73E8',
    }],
    ['text/plain', {
        type: 'txt',
        icon: '/assets/icons/txt.svg',
        color: '#6c757d',
    }],
    ['application/vnd.ms-excel', {
        type: 'xls',
        icon: '/assets/icons/xls.svg',
        color: '#198754',
    }]
])


