import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceSubstring'
})
export class ReplaceSubstringPipe implements PipeTransform {
    transform(input: string, search: string, replacement: string): string {
        if (!input || !search) {
            return input;
        }

        return input.replace(search, replacement);
    }
}
