import {Injectable} from '@angular/core'
import {BaseService} from '../../@core/base-service/base.service'
import {HttpClient} from '@angular/common/http'
import {firstValueFrom} from 'rxjs'

@Injectable({providedIn: 'root'})
export class UserService extends BaseService<any> {
    constructor(http: HttpClient) {
        super(http)
    }

    setDefaults() {
        this.setResource('user')
    }

    async fetchMe(): Promise<any> {
        return firstValueFrom(this.http.get(`${this.resource}/me`))
    }

    async changePassword(payload: {oldPassword: string, newPassword: string}): Promise<any> {
        return firstValueFrom(this.http.put(`${this.resource}/password`, payload))
    }

    async updateName(payload: {firstname: string, surname: string}): Promise<any> {
        return firstValueFrom(this.http.put(`${this.resource}/name`, payload))
    }

    async resetPassword(password: string, userId: string): Promise<any> {
        return firstValueFrom(this.http.put(`${this.resource}/${userId}/reset-password`, {password}))
    }
}
