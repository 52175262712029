import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mapPipe'
})
export class MapPipe implements PipeTransform {
    transform(items: any[], propertyName: string): any[] {
        if (!items || !propertyName) {
            return items;
        }

        return items.map(item => item[propertyName]);
    }
}
