import {Injectable} from '@angular/core'
import {BaseService} from '../../@core/base-service/base.service'
import {
    IProposal,
    IProposalFile, IProposalProcessError,
    IProposalProcessPayload,
    IQuickbooksMessagePayload,
    ProposalStatus
} from './proposal.types'
import {FolderTypes} from '../folder/folder.types'
import {firstValueFrom} from 'rxjs'
import {HttpClient} from '@angular/common/http'

@Injectable({providedIn: 'root'})
export class ProposalService extends BaseService<any> {
    constructor(http: HttpClient) {
        super(http)
    }

    setDefaults() {
        this.setResource('proposal')
    }

    async updateItem(id: string, payload: any): Promise<any> {
        try {
            return firstValueFrom(this.http.patch(`${this.resource}/${id}`, payload))
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async uploadFiles(files: any, id: string, folderType: FolderTypes): Promise<{createdFiles: any[]}> {
        return firstValueFrom(this.http.post<{createdFiles: any[]}>(`${this.resource}/${id}/upload?folderType=${folderType}`, files))
    }

    async uploadResultFiles(files: any, id: string): Promise<{createdFiles: any[]}> {
        return firstValueFrom(this.http.post<{createdFiles: any[]}>(`${this.resource}/${id}/upload-result?folderType=${FolderTypes.RESULT}`, files))
    }

    async processFiles(payload: IProposalProcessPayload, id: string, folderType: FolderTypes): Promise<{
        processFile: IProposalFile,
        errors: IProposalProcessError[]
    }> {
        return firstValueFrom(this.http.post<{
            processFile: IProposalFile,
            errors: IProposalProcessError[]
        }>(`${this.resource}/${id}/process?folderType=${folderType}`, payload))
    }

    async sendQuickbooksMessage(payload: IQuickbooksMessagePayload, id: string): Promise<IProposal> {
        return firstValueFrom(this.http.post<IProposal>(`${this.resource}/${id}/quickbooks/send-message`, payload))
    }

    async updateProposalStatus(id: string, status: ProposalStatus): Promise<IProposal> {
        return firstValueFrom(this.http.put<IProposal>(`${this.resource}/${id}/update-status`, {status}))
    }

    async archiveProposal(id: string, archived: boolean): Promise<IProposal> {
        return firstValueFrom(this.http.put<IProposal>(`${this.resource}/${id}/archive`, {archived}))
    }

    async processProposalFolder(proposalId: string, proposalFolderId: string) {
        return firstValueFrom(this.http.post<any>(`${this.resource}/${proposalId}/process-folder`, {proposalFolderId}))
    }

    async updateProposalQuickbooksIntegrated(id: string, payload: {quickbooksIntegrated: boolean}): Promise<IProposal> {
        return firstValueFrom(this.http.put<IProposal>(`${this.resource}/${id}/quickbooks-integrated`, payload))
    }

    async processResultFiles(payload: {resultFileId: string; productIds: string[]}, id: string): Promise<IProposalFile> {
        return firstValueFrom(this.http.post<IProposalFile>(`${this.resource}/${id}/process-result`, payload))
    }

    async connectProvider(id: string, payload: any): Promise<{success: boolean}> {
        return firstValueFrom(this.http.post<{success: boolean}>(`${this.resource}/${id}/connect-provider`, payload))
    }

    async connectProviderFromInvitation( payload: any): Promise<{success: boolean}> {
        return firstValueFrom(this.http.post<{success: boolean}>(`${this.resource}/connect-provider-from-invitation`, payload))
    }
}
