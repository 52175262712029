<mat-toolbar class="position-fixed z-3">
    <mat-toolbar-row class="container d-flex align-items-center px-0 justify-content-between">
        <img src="/assets/logo.png" alt="Attentive" class="logo pointer me-5" routerLink="/">
        <div>
            <button mat-stroked-button color="primary" routerLink="/help-center" class="me-3">
                <mat-icon class="material-symbols-outlined">help</mat-icon>
                <span>Help Center</span>
                </button>
            @if (authService.token) {
                <button mat-raised-button color="primary" routerLink="/app/proposals">
                    <mat-icon>arrow_back</mat-icon>
                    <span class="d-none d-md-block">Back to Proposals</span>
                </button>
            } @else {
                <button mat-raised-button color="primary" routerLink="/auth/sign-in" class="me-3">
                    <mat-icon class="material-symbols-outlined">login</mat-icon>
                    <span>Sign In</span>
                </button>

            }

<!--            <button mat-raised-button routerLink="/auth/sign-up" class="ms-3" color="primary">Sign Up</button>-->
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<div class="container" [class.vh-100]="isOnAuthRoute" style="padding: 112px 0 !important;">
    <router-outlet></router-outlet>
</div>
