import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {MatDialogModule} from '@angular/material/dialog'
import {MatButtonModule} from '@angular/material/button'
import {MatIconModule} from '@angular/material/icon'
import {DeleteDialogComponent} from './delete-dialog.component'
import {DeleteDialogService} from './delete-dialog.service'
import {MatCardModule} from '@angular/material/card'


@NgModule({
  declarations: [DeleteDialogComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
    ],
  providers:[DeleteDialogService]
})
export class DeleteDialogModule {
}
