import {Component, inject, OnInit} from '@angular/core'
import {AuthService} from './auth.service'
import {ActivatedRoute} from '@angular/router'

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
    public authService: AuthService = inject(AuthService)

    isOnAuthRoute: boolean = inject(ActivatedRoute).snapshot.routeConfig.path === 'auth'
}
