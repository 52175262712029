import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {LoginResponse} from './login/login.interface'
import {OAKMORE_ATTENTIVE} from '../shared/consts/storage.const'
import {Router} from '@angular/router'
import {UserService} from '../protected/user/user.service'
import {IGroup} from '../protected/group/groups.types'
import {IUser} from '../protected/user/user.types'
import {IAccount} from '../protected/account/account.types'
import {firstValueFrom} from 'rxjs'

@Injectable({providedIn: 'root'})
export class AuthService {
    private http: HttpClient
    _token: any | null

    user$: {user: IUser, account: IAccount}

    constructor(
        http: HttpClient,
        public userService: UserService,
        private router: Router
    ) {
        this.http = http as HttpClient
        this.setInitialCredentials()
    }

    setInitialCredentials() {
        const savedCredentials = localStorage.getItem(OAKMORE_ATTENTIVE)

        if (savedCredentials) {
            this.setToken(savedCredentials)
        }
    }

    async login(email: string, password: string): Promise<string> {
        try {
            const {access_token} = await firstValueFrom(this.http.post<LoginResponse>(`login`, {email, password}))
            this.setToken(access_token)
            await this.fetchMe()
            return access_token
        } catch (e) {
            throw e
        }
    }

    async signup(payload: {
        email: string,
        password: string,
        firstname: string,
        surname: string,
        groups: IGroup
    }): Promise<any> {
        try {
            return firstValueFrom(this.http.post('signup', payload))
            // return this.login(email, password)
        } catch (e) {
            throw e
        }
    }

    async logout(): Promise<boolean> {
        this.setToken(null)
        return this.router.navigate(['/auth'], {replaceUrl: true})
    }

    isAuthenticated(): boolean {
        return !!this.token
    }

    async fetchMe() {
        const credentials: any = localStorage.getItem(OAKMORE_ATTENTIVE)
        if (credentials) {
            try {
                this.user$ = await this.userService.fetchMe()
            } catch (e) {
                this.user$ = null
                this.setToken(null)
            }
        }

        return this.user$
    }

    get token(): any | null {
        return this._token
    }


    setToken(token?: any) {
        this._token = token || null

        if (token) {
            localStorage.setItem(OAKMORE_ATTENTIVE, token)
        } else {
            localStorage.removeItem(OAKMORE_ATTENTIVE)
        }
        return
    }

    isSuperAdmin() {
        return this.user$?.user?.isSuperAdmin
    }

    hasPermissions(permission: string) {
        if (!this.isSuperAdmin()) {

            const allPermissions: string[] = [...new Set([
                ...this.user$?.account?.additionalRoles?.map(role => role.permissions).flat(),
                ...this.user$?.account?.role?.permissions])
            ]

            return allPermissions?.includes(permission)

        }
        return true

    }
}
