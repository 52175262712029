import {Component, Inject, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {ThemePalette} from '@angular/material/core'
import {DeleteDialogProps} from './delete-dialog.types'

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  title: string
  text: string
  okText: string
  cancelText: string
  primaryBtn: ThemePalette | any

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteDialogProps,
  ) {}

  ngOnInit(): void {
    this.title = this.data?.title || 'Do you want to delete this item?'
    this.text = this.data?.text || 'Are you sure you want to delete this item? This action cant be undone.'
    this.okText = this.data?.okText || 'Delete'
    this.cancelText = this.data?.cancelText || 'Cancel'
    this.primaryBtn = this.data?.primaryBtn || 'warn'
  }
}
