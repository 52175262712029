import {Injectable} from '@angular/core'
import {BaseService} from '../../@core/base-service/base.service'
import {HttpClient} from '@angular/common/http'
import {firstValueFrom} from 'rxjs'
import {DownloadType} from './file.types'

@Injectable({providedIn: 'root'})
export class FileService extends BaseService<any> {
    constructor(http: HttpClient) {
        super(http)
    }

    setDefaults() {
        this.setResource('file')
    }

    async deleteFiles(ids: string[], isResultFile: boolean): Promise<any> {
        return firstValueFrom(this.http.delete(`${this.resource}?ids=${encodeURIComponent(JSON.stringify(ids))}&isResultFile=${encodeURIComponent(JSON.stringify(isResultFile))}`))
    }

    async downloadFile(id: string, downloadType:DownloadType): Promise<any> {
        return firstValueFrom(this.http.get(`${this.resource}/${id}/download?downloadType=${downloadType}`, {responseType: 'arraybuffer'}))
    }

    async getDebugFiles(id: string): Promise<any> {
        return firstValueFrom(this.http.get(`${this.resource}/${id}/debug`, {responseType: 'arraybuffer'}))
    }
}
