import {Injectable} from '@angular/core'
import {BaseService} from '../../@core/base-service/base.service'
import {HttpClient} from '@angular/common/http'

@Injectable({providedIn: 'root'})
export class AccountService extends BaseService<any> {
    constructor(http: HttpClient) {
        super(http)
    }

    setDefaults() {
        this.setResource('account')
    }
}
