import {ThemePalette} from '@angular/material/core'

export type DeleteDialogProps = {
  title?: string
  text?: string
  okText?: string
  cancelText?: string
  primaryBtn?: ThemePalette | any
  width?: string
}
export const DeleteDialogDefaults = {
  // width: '43.2rem'
  width: '400px'
}
export type DeleteDialogReturn = boolean
