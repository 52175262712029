import {inject, Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router'
import {AuthService} from './auth.service'

@Injectable()
export class AuthenticationGuard implements CanActivate {
    private router: Router = inject(Router)
    private authService: AuthService = inject(AuthService)

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.isAuthenticated()) {
            return true
        }

        const redirectUrl: string = encodeURIComponent(state.url)
        this.router.navigate(['/auth'], {replaceUrl: true, queryParams: {redirectUrl}}).then()
        return false
    }
}
