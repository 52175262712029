import {Component} from '@angular/core'
import {FOOTER_CONST} from './shared/consts/footer.const'
import {environment} from '../environments/environment'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    footer: string = FOOTER_CONST
    appVersion: string = environment.versions.app
    buildTime: string = environment.versions.buildTime
}
