import {Injectable} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {filter} from 'rxjs/operators'

import {Observable} from 'rxjs'
import {DeleteDialogDefaults, DeleteDialogProps, DeleteDialogReturn} from './delete-dialog.types'
import {DeleteDialogComponent} from './delete-dialog.component'

@Injectable({providedIn: 'root'})
export class DeleteDialogService {
  constructor(public dialog: MatDialog) {}

  async confirm(data?: DeleteDialogProps): Promise<DeleteDialogReturn> {
    return this.makeRef(data).afterClosed().toPromise()
  }

  confirmed(data?: DeleteDialogProps): Observable<DeleteDialogReturn> {
    return this.makeRef(data).afterClosed().pipe(filter(c => c === true))
  }

  private makeRef(data?: DeleteDialogProps) {
    return this.dialog.open(DeleteDialogComponent, {
      ...DeleteDialogDefaults,
      data,
      width: data?.width || DeleteDialogDefaults.width
    })
  }
}
