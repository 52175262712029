<mat-card>
  <mat-card-header class="d-flex align-items-center justify-content-between px-4">
      <mat-card-title>

          <h2 class="modal-title mb-0" [innerHTML]="title"></h2>
      </mat-card-title>
      <mat-icon (click)="dialogRef.close()" class="pointer">close</mat-icon>
  </mat-card-header>

  <mat-dialog-content class="">
    <div [innerHTML]="text"></div>
  </mat-dialog-content>

  <mat-card-footer class="d-flex align-items-center justify-content-end mt-3 pb-4 px-4">
    <button mat-stroked-button (click)="dialogRef.close()"  color="primary">{{cancelText}}</button>
    <button mat-raised-button [color]="primaryBtn"
            (click)="dialogRef.close(true)"
            class="ms-3"
            cdkFocusInitial>
      {{okText}}
    </button>
  </mat-card-footer>
</mat-card>
