import {APP_INITIALIZER, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {ApiEndPointInterceptor} from './@core/http/interceptors/api-end-point.interceptor'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {ProposalService} from './protected/proposal/proposal.service'
import {FolderService} from './protected/folder/folder.service'
import {MatSnackBarModule} from '@angular/material/snack-bar'
import {AuthenticationGuard} from './auth/authentication.guard'
import {UserService} from './protected/user/user.service'
import {AuthService} from './auth/auth.service'
import {AuthTokenInterceptor} from './@core/http/interceptors/auth-token.interceptor'
import {ErrorHandlerInterceptor} from './@core/http/interceptors/error-handler.interceptor'
import {AccountService} from './protected/account/account.service'
import {GroupService} from './protected/group/group.service'
import {RuleService} from './protected/rule/rule.service'
import {FileService} from './protected/file/file.service'
import {DeleteDialogModule} from './shared/components/delete-dialog/delete-dialog.module'
import {PipesModule} from './shared/pipes/pipes.module'
import {SnackBarService} from './shared/snackbar/SnackBar.service'
import {SnackbarComponent} from './shared/snackbar/snackbar.component'
import {MatButtonModule} from '@angular/material/button'
import {MatIconModule} from '@angular/material/icon'
import {MAT_DATE_LOCALE} from '@angular/material/core'
import {MatToolbar} from '@angular/material/toolbar'

@NgModule({
    declarations: [
        AppComponent,
        SnackbarComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatSnackBarModule,
        DeleteDialogModule,
        PipesModule,
        MatButtonModule,
        MatIconModule,
        MatToolbar
    ],
    providers: [
        AuthService,
        ProposalService,
        FolderService,
        SnackBarService,
        AuthenticationGuard,
        UserService,
        AccountService,
        GroupService,
        RuleService,
        FileService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        },
        {
            provide:
            MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                floatLabel: 'always',
                appearance: 'outline',
                subscriptSizing: 'dynamic',
            },
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'en-US',
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiEndPointInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function appInitializer(
    authService: AuthService
): () => Promise<any> {
    return async (): Promise<any> => {
        return new Promise(async (resolve) => {
            const me = await authService.fetchMe()
            return resolve([me])
        })
    }
}
