
import {EnvTypes} from './env.types'

export const environment: EnvTypes = {
  production: false,
  version: '0.95',
  API_URL: 'https://attentive-proposal-tool-back-end-dev.onrender.com/api/v1/',
  BUILD_TIME: '2024-08-07T09:39:55.847Z',
  GOOGLE_DRIVE_PROPOSAL_FOLDER_ID: 'undefined',
  GOOGLE_DRIVE_RESULT_FOLDER_ID: 'undefined',
  DECISION_RULES_APP_URL: 'undefined',
  versions: {
    app: '0.95',
    buildTime: '2024-08-07T09:39:55.848Z'
  }
}
