import {Injectable} from '@angular/core'
import {BaseService} from '../../@core/base-service/base.service'
import {IRule} from './rule.types'
import {HttpClient} from '@angular/common/http'
import {firstValueFrom} from 'rxjs'

@Injectable({providedIn: 'root'})
export class RuleService extends BaseService<any> {
    constructor(http: HttpClient) {
        super(http)
    }

    setDefaults() {
        this.setResource('rule')
    }

    async fetchRuleDetail(id: string, version: number): Promise<IRule> {
        try {
            return firstValueFrom(this.http.get<IRule>(`${this.resource}/${id}/${version}`))
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
}
