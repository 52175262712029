import {NgModule} from '@angular/core'
import {MapPipe} from './map.pipe'
import {ReplaceSubstringPipe} from './replace-substring.pipe'
import {FileIconPipe} from './file-icon.pipe'
import {FileTypePipe} from './file-type.pipe'

@NgModule({
    declarations: [
        MapPipe,
        ReplaceSubstringPipe,
        FileIconPipe,
        FileTypePipe
    ],
    imports: [],
    exports: [
        MapPipe,
        ReplaceSubstringPipe,
        FileIconPipe,
        FileTypePipe
    ],
    providers: []
})
export class PipesModule {
}
